





















import './styles/ProjectsBoard.scss';
import './styles/ProjectsBoardAdaptive.scss';
import {Component, Prop, Vue} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUserProject } from '@store/modules/user-projects/Interfaces';

const NSUserProjects = namespace('storeUserProjects');

@Component({
    name: 'ProjectsBoard',

    components: {
        'ProjectCard': () => import('../components/ProjectCard.vue'),
    },
})
export default class ProjectsBoard extends Vue {
    @NSUserProjects.Getter('activeProjects') activeProjects!: IUserProject[];

    @Prop({}) viewMode!: string;

    get projectBoardViewMode() {
        return `projects-board--${this.viewMode}`
    }
}
